import * as THREE from 'three'
import { useRef, useState, useEffect } from 'react'
import { useMatch, useNavigate, Link } from 'react-router-dom'
import { Canvas, extend, useFrame, useThree, useLoader  } from '@react-three/fiber'
// check if a particular route matches the current location 
import { useRoute, useLocation } from 'wouter'
import { useCursor, MeshPortalMaterial, CameraControls, Gltf, Text, OrbitControls} from '@react-three/drei'
import { easing, geometry } from 'maath'
import { suspend } from 'suspend-react'
import NavBarSearch from '../Nav/TopNavBarV2'
import './Education.css'
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';

extend(geometry)

const Earth = () => {
	// Here we load the model from a CloudFront URL
	const gltf = useLoader(GLTFLoader, 'https://d3r28sgboo2i78.cloudfront.net/earth_hologram_style_3d_hud/scene.gltf')
    
	return (
		<primitive 
			object={gltf.scene}
			scale={0.32} 
			position={[0, 0, -3]} 
			rotation={[0.1, -0.3, 0]}
		/>
	);
};

/**
 * 
 * @param {gltf path in bucket} gltfPath 
 * @param {decimal point value} scale 
 * @param {Array (x,y,z) plane} position 
 * @param {Array (x,y,z) plane} rotation 
 * @returns 
 */
const Model = ({ gltfPath, scale, position, rotation, onClick }) =>{
	const [hovered, setHover] = useState(false)
	useCursor(hovered)

	const distributionUrl = "https://d3r28sgboo2i78.cloudfront.net"
	// Here we load the model from a CloudFront URL
	const gltf = useLoader(GLTFLoader, `${distributionUrl}/${gltfPath}`)
    
	return (
		<primitive 
			object={gltf.scene}
			scale={scale} 
			position={position} 
			rotation={rotation}
			onPointerOver={()=> setHover(true)} 
			onPointerOut={() =>setHover(false)} 
			onClick={onClick()}
		/>
	);
}

const EducationPage = ()=>{
	const navigate = useNavigate()
	const [hovered, setHover] = useState(false)
	useCursor(hovered)

	const currentUser  = localStorage.getItem('currentUser');
	const currentProfile = localStorage.getItem("profile_name");
	const profileId = localStorage.getItem("selected_prof_id");
	const regionObj = localStorage.getItem("region") ? JSON.parse(localStorage.getItem("region")): {};
	const region = regionObj[profileId];

	const showNorthernCountries=()=>{
		console.log('north')
	}

	const showWesternCountries = ()=>{
		console.log('west')
	}

	return(
		<div>
			<NavBarSearch region={region} profile={currentProfile}/>
			<Canvas camera={{ fov: 75, position: [0, 0, 20] }} eventSource={document.getElementById('root')} eventPrefix="client">    
				<color attach="background" args={['#381837']} />
				<Frame id="north" name="North" bg="#C92941" position={[-3, 2, 0]} rotation={[0, 0.5, 0]}>
					<ambientLight intensity={0.4} />
					<directionalLight position={[10, 10, 10]} intensity={1.0} /> 
					<Model gltfPath="morocco/scene.gltf" scale={1.0} position={[0, 0.4, -2]} onClick={()=>showNorthernCountries}/>
                
					{/* <Gltf 
                        onPointerOver={()=> setHover(true)} 
                        onPointerOut={() =>setHover(false)} 
                        onClick={()=> showNorthernCountries} 
                        src="/glbs/morocco/scene.gltf" 
                        scale={1.0} 
                        position={[0, 0.4, -2]} 
                    /> */}
				</Frame>
				<Frame id="south" name="South" bg="#C92941" position={[-3, -0.5, 0]} rotation={[0, 0.5, 0]}>
					<ambientLight intensity={0.4} />
					<directionalLight position={[10, 10, 10]} intensity={1.0} /> 
					<Model gltfPath="south_africa/scene.gltf" scale={1.0} position={[0, 0.2, -2]} onClick={()=>showNorthernCountries}/>
				</Frame>
                
				<Earth/>
                
				<Frame id="east" name="East" bg="#C92941" position={[3, -0.5, 0]} rotation={[0, -0.5, 0]}>
					<ambientLight intensity={0.4} />
					<directionalLight position={[10, 10, 10]} intensity={1.0} /> 
					<Model gltfPath="somalia/scene.gltf" scale={1.0} position={[0.3, 0.1, -2]} onClick={()=>showNorthernCountries}/>
				</Frame>

				{/* position: x,y,z */}
				<Frame id="west" name="West" bg="#C92941" position={[3, 2, 0]} rotation={[0, -0.5, 0]}>  
					{/* ranges from 0 (completely off) to typically around 1 (full intensity), although values can exceed 1 for a brighter light if needed. */} 
					<ambientLight intensity={0.4} />
					<directionalLight position={[10, 10, 10]} intensity={1.0} /> 
					<Model gltfPath="ghana/scene.gltf" scale={1.0}  position={[-5, 0.4, -2]}  onClick={()=>showWesternCountries}/>
                    
					<Model gltfPath="nigeria_flag_3d/scene.gltf" scale={1.0} position={[0, 0.4, -2]}  onClick={()=>showWesternCountries}/>
					{/* <Gltf src="/glbs/nigeria_flag_3d/scene.gltf" scale={1.0} position={[0, 0.4, -2]} /> */}
				</Frame>

				<Rig /> {/* control the camera based on route parameters and to add lights to the scene */}
			</Canvas>
		</div>
	)

}

/**
 * 
 * @param {width}
 * The width of the card
 * @param {name}
 * The name for the card
 * @returns 
 */ 
function Frame({ id, name, author, bg, width = 2.5, height = 1.8, children, ...props }) {
	const navigate = useNavigate()
	const [, setLocation] = useLocation() //wouter location
	const portal = useRef()
	const [check, params] = useRoute('/region/:id')
	const [hovered, hover] = useState(false)
	useCursor(hovered)
	useFrame((state, dt) => easing.damp(portal.current, 'blend', params?.id === id ? 1 : 0, 0.2, dt))

	return (
		<group {...props}>
			<Text fontSize={0.3} anchorY="top" anchorX="left" lineHeight={0.8} position={[-0.375, 0.715, 0.01]} material-toneMapped={false}>
				{name}
			</Text>
       
			<mesh name={id} 
				onClick={(e) => (e.stopPropagation(), setLocation('/region/' + e.object.name))} 
				onPointerOver={(e) => hover(true)} 
				onPointerOut={() => hover(false)}>
				<roundedPlaneGeometry args={[width, height, 0.1]}/>
				<MeshPortalMaterial ref={portal} events={params?.id === id} side={THREE.DoubleSide}>
					<color attach="background" args={[bg]} />
					{children}
				</MeshPortalMaterial>
			</mesh>
		</group>
	)
}

// Adjust x, y,z camera starting angle
function Rig({ position = new THREE.Vector3(0, 0, 5), focus = new THREE.Vector3(0, 0, 0) }) {
	const { camera, gl, controls, scene } = useThree()
	// const orbitRef = useRef();
	// check is boolean
	const [check, params] = useRoute('/region/:id')

	// useEffect(() => {
	//     const controls = orbitRef.current;
	//     if (controls) {
	//       // Enable or disable features
	//       controls.enableZoom = true; // enable zooming
	//       controls.enablePan = true; // enable panning
	//       controls.enableRotate = true; // enable rotation
	//       controls.autoRotate = false; // enable auto-rotation
	//       controls.panSpeed = 2; // Adjust panning speed, if necessary
	//       controls.zoomSpeed = 2; // Adjust zoom speed, if necessary
	//       controls.rotateSpeed = 2; // Adjust rotate speed, if necessary
	//     }
	// }, [orbitRef]);

	useEffect(() => {
		const active = scene.getObjectByName(params?.id)

		// This is majorly for the glbs or components outside of the Frame so modifies lighting direct models in the canvas
		const ambientLight = new THREE.AmbientLight(0xffffff, 0.7);
		scene.add(ambientLight);

		const dirLight = new THREE.DirectionalLight(0xefefff, 0.7);
		dirLight.position.set( 10, 10, 10 );
		scene.add(dirLight);

		if (active) {
			active.parent.localToWorld(position.set(0, 0.5, 0.25))
			active.parent.localToWorld(focus.set(0, 0, -2))
		}
		// orbitRef.current?.update();
		controls?.setLookAt(...position.toArray(), ...focus.toArray(), true)
	})

	return <CameraControls makeDefault minPolarAngle={0} maxPolarAngle={Math.PI / 2} />
}
  
export default EducationPage;