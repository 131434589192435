import { useContext, useMemo } from 'react';
import { ThemeContext } from '../context/ThemeContext.jsx';

const useThemeStyle = () => {
	const { darkMode, darkTheme, lightTheme } = useContext(ThemeContext);

	const theme = useMemo(() => {
		return darkMode ? darkTheme : lightTheme;
	}, [darkMode, darkTheme, lightTheme]);

	const style = {
		background: theme.background,
		color: theme.text,
		transition: '1s ease-in-out'
	};

	return style;
};

export default useThemeStyle;
