import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import SearchBar from '../Nav/SearchComponent';
import ModalCountrySelection from '../MoviesPage/ModalCountrySelection';
import './NavBarSearch.css';

const NavBarSearch=(props)=>{
	const [isModal, setModalDisplay] = useState(false);
	useEffect(()=>{
		//console.log(props)
	})

	const toggleModal=()=>{
		isModal ? setModalDisplay(false):  setModalDisplay(true);
	}

	return(
		<nav className="navbar navbar-expand-lg navbar-light bg-dark" style={{boxShadow:'0px 4px 10px rgb(0 0 0 / 25%)'}}>
			<Link onClick={props.navToggle} ><img className="navigation" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1619866913/AfroWatch/Group_3_rnlqdu.svg" alt=""/></Link>
			<a className="navbar-brand" href="/"><img className="navbarbrand-img" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1618744963/AfroWatch/Afrowatch_Horizonal_Logo_-_White_pq1iyh.png" alt=""/></a>
            
			<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
				<span className="navbar-toggler-icon"></span>
			</button>

			<div>
				{props.region ? <span className='ml-5'><Link style={{color:'white'}} to='#' onClick={toggleModal}>Region: {props.region}</Link></span> : <span className="text-white" style={{color: 'white'}}>No region selected yet</span>}
			</div>

			{isModal ? <ModalCountrySelection closed={toggleModal} /> : null}

			<div className="collapse navbar-collapse" id="navbarSupportedContent">
				<ul className="navbar-nav ml-auto">
                   
					<SearchBar/>
					<button className="notification-btn border-0 px-md-5"><img src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1619866913/AfroWatch/Group_4_rc9j1n.svg" alt=""/></button>
					<Link className="pr-md-3" to="/profile/123"><img className="profile-icon" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1619866914/AfroWatch/Group_5_pifpe3.svg" alt=""/></Link>
                
				</ul>
			</div>
		</nav>
	)
}

export default NavBarSearch;