import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./MovieCard.css";
import PlayButton from "./PlayButton";

const MovieCard = (props) => {
	const [cardState, setCardState] = useState({
		hover: false,
		played: false,
		dragging: false,
	});

	const navigate = useNavigate();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const handleMouseDown = () => {
		setCardState((prevState) => ({ ...prevState, dragging: false }));
	};

	const handleMouseMove = () => {
		setCardState((prevState) => ({ ...prevState, dragging: true }));
	};

	const handleMouseUp = () => {
		if (!cardState.dragging) {
			navigate(`/watch/${props.content_id}`, {
				state: {
					content_id: props.content_id,
					description: props.description,
					content_name: props.content_name,
					actors: props.actors,
					genre: props.genre,
					length: props.length,
					header_image: props.header_image,
				},
			});

			window.scrollTo(0,0)
		}
	};

	return (
		<div
			key={props.content_id}
			onMouseDown={handleMouseDown}
			onMouseMove={handleMouseMove}
			onMouseUp={handleMouseUp}
			onMouseEnter={() => setCardState({ ...cardState, hover: true })}
			onMouseLeave={() => setCardState({ ...cardState, hover: false })}
			className="movie-card-container"
		>
			<div className="movie-card">
				{!props.hidePlayButton && <PlayButton />}
				<img src={props.image} alt="movie_img" className="content_image" />
				<span className="text-desc">
					<span className="text-desc-title">
						{window.innerWidth < 600 ? (props.content_name.length < 20 ? props.content_name : props.content_name.substring(0, 20)) : props.content_name}
					</span>
				</span> 
			</div>
		</div>

	);
};

export default MovieCard;
