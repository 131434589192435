import React, { createContext, useState } from 'react';

export const ModalContext = createContext();

const ModalContextProvider = ({ children }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	const closeModal = () => {
		if (isModalOpen) {
			setIsModalOpen(false);
		}
	};

	const openModal = () => {
		if (!isModalOpen) {
			setIsModalOpen(true);
		}
	};

	return (
		<ModalContext.Provider
			value={{
				isModalOpen,
				closeModal,
				openModal,
			}}
		>
			{children}
		</ModalContext.Provider>
	);
};

export default ModalContextProvider;
