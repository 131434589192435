import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {ProgressBar} from 'react-bootstrap'
import '../MoviesPage/MovieCard.css';
import { height } from '@mui/system';

const FilterMovieCard=(props)=>{
	const [intitialState, setCardState] = useState({hover:false, played:false})

	useEffect(()=>{
        
	},[])

	let imgStyle ={
		width:'250px',
		height:'340px',
		borderRadius:'20px'
	}

	return(
		<Link key={props.content_id} to={`/watch/${props.content_id}`} state={{content_id:props.content_id, 
			description:props.description,
			content_name:props.content_name,
			actors:props.actors, 
			genre:props.genre,
			length:props.length
		}} 
		onFocus={(e)=>intitialState} 
		onMouseEnter={(e)=>setCardState({hover:true})} 
		onMouseLeave={(e)=>setCardState({hover:false})}>

			<div className="movie-card pb-4">
				<img style={{position:'absolute',left: '120px',top:'136px'}} src='https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620946412/AfroWatch/Component_3_zf2imv.svg'/>
				<img src={props.image} style={imgStyle} alt="movie_img"/>
				{/* <ProgressBar style={{width:'250px'}} variant="progress-custom" now={40} min={0}/>             */}
			</div>
		</Link>
	)

}

export default FilterMovieCard;