import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import videojs from "video.js";
import { setProfileStreamPlayBackData, uploadUserPlayBackData, getContentPlayBackDataFromDB } from "./controls/_playback";
import { VIDEO_PLAYER_OPTIONS } from "../constants";
import {PLAYBACK_TRACKING_OPTS} from "../constants";
import { applyTimelineListenersToPlayer } from "./controls/_timeline";
import { getContentStreamPlayBackData } from "./controls/_playback";
import PlayBackTracker from "./PlayBackTracker";
import "video.js/dist/video-js.css";
import "./AfrowatchVideoJS.css";
import {
	formatVideoTime,
	resolveSourceTypeFromVideoType,
	TimeDifferenceCalc,
} from "./controls/_utils";
import { getOrCreatePosterThumbnail } from "./streamParser";
import { axiosInstance } from "../../apiClient";

const AfrowatchVideoPlayer = (props) => {
	const [player, setPlayer] = useState(null);
	const [streamData, setStreamData] = useState();
	window.VIDEOJS_NO_DYNAMIC_STYLE = true;

	const videoRef = useRef(null);
	const playerRef = useRef(null);
	const { contentName, options, onReady, tracks, language } = props;
	const [isHovered, setIsHovered] = useState(false);
	const playerContainerName = props.navDrawer || `video-container ${isHovered ? 'hovered' : ''}`;
	const userId = localStorage.getItem('currentUser')
	const profileId = localStorage.getItem("selected_prof_id");

	const [lastPlayedTime, setLastPlayedTime] = useState(0);

	const localStorageKey = 'lastPlayedTime';

	const params = useParams();
	let subtitlesList = [];
	try {
		subtitlesList =
			props.streamData !== undefined ? [props.streamData.stream.subtitles_list] : [""];
	} catch (err) {
		console.log(err);
	}

	useEffect(() => {
		setStreamData(props.streamData);
	}, [props.streamData]);

	const addToWatchingNow = async(currentTime, duration) => {
		try {
			const res = await axiosInstance.put(`/${userId}/user/profile/${profileId}/watching_now/${params.contentid}`, {position: currentTime, totalDuration:duration});
			if(res.data.status === 200){
				console.log(res.data.message)
			}
		} catch (error) {
			console.log(error)
		}

	};

	useEffect(() => {
		try {
			let player_;
			if (!playerRef.current) {
				// The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
				const videoElement = document.createElement("video-js");
				videoElement.classList.add("vjs-big-play-centered");
				videoRef.current.appendChild(videoElement);

				const player = (playerRef.current = videojs(
					videoElement,
					{ ...options },
					() => {
						videojs.log("player is ready");
						onReady && onReady(player);
					}
				));
				// Trigger caption button to show languges
				player.ready(function () {
					var controlBar = player.controlBar;

					// Get the caption button
					var captionsButton = controlBar.getChild("captionsButton");

					if (captionsButton) {
						// If the caption button exists, remove it from its current position
						controlBar.removeChild(captionsButton);
					}
					// player.addRemoteTextTrack(captionOption);
					tracks.length > 0
						? tracks.map((track) => {
							try {
								player.addRemoteTextTrack(track);
								const tracks = player.remoteTextTracks();
								for (let i = 0; i < tracks.length; i++) {
									const track = tracks[i];
									if (track.kind === "subtitles" && track.src.length > 0) {
										track.mode = "showing";
									}
								}
							} catch (error) {
								console.error("Error adding caption track:", error);
							}
						})
						: null;

				});

				player.controlBar.addChild("CaptionsButton", {}, 2);
				player.language(language.code);
				player_ = player;
				player_.getChild("LoadingSpinner").el().innerHTML ='<img src="/afrowatch-loading-spinner.svg" alt="Loading...">';

				setPlayer(player_);
			} else {
				const player = playerRef.current;

				player.autoplay(options.autoplay);
				player.src(options.sources);
			}

			if (props.streamData) {
				// populate source
				// player_.src({
				// 	src: props.streamData && props.streamData.stream['hls_stream_path'] ? `${process.env.REACT_APP_BASE_STREAM_URL}/${props.streamData.stream['hls_stream_path']}` : `https://assets.afrowatch.co/2de42454-c673-test-video-two/Default/HLS/2de42454-c673-test-video-two_360.m3u8`,
				// 	type: props.streamData.stream['video_content_type'] ? resolveSourceTypeFromVideoType(props.streamData.stream['video_content_type']) : 'application/x-mpegURL',
				// 	//withCredentials: true
				// })

				//add poster image if any
				let poster_thumbnail = getOrCreatePosterThumbnail(props.streamData);

				if (poster_thumbnail != null) {
					player_.poster(
						`${process.env.REACT_APP_BASE_STREAM_URL}/${poster_thumbnail}`
					);
				}

				if (props.streamData.stream.subtitles_list) {
					subtitlesList.map((subtitleData) => {
						const { language, url, label } = subtitleData;
						player_.addRemoteTextTrack(
							{
								kind: "captions",
								srcLang: language,
								src: url,
								mode: "hidden",
								label: label,
							},
							false
						);
					});
				}


				// Event listener to toggle hover state for styling
				const videoContainer = playerRef.current.el();
				videoContainer.addEventListener('mouseenter', () => setIsHovered(true));
				videoContainer.addEventListener('mouseleave', () => setIsHovered(false));

				// Load the last played time from localStorage
				const savedTime = parseFloat(localStorage.getItem(localStorageKey));
				if (!isNaN(savedTime)) {
					setLastPlayedTime(savedTime);
					playerRef.current.currentTime(savedTime);
				}

				try {
					getContentPlayBackDataFromDB(userId, profileId, params.contentid)
				} catch (err) {
					console.log(`NO CONTENT ID FOUND FOR PLAYBACK`)
					console.log(err)
				}
				const player = playerRef.current;
				player.on('play', () => {
					console.log('Video has started playing!');
					// Add to watching now
					addToWatchingNow(playerRef.current.currentTime(), playerRef.current.duration())
				});
				player.ready(function () {
					// 	// Update the last played time in localStorage
					const updateTimeCalculator = TimeDifferenceCalc(PLAYBACK_TRACKING_OPTS.playbackDataUpdateInterval)
					const uploadTimeCalculator = TimeDifferenceCalc(PLAYBACK_TRACKING_OPTS.playbackDataUploadInterval)
					
					playerRef.current.on('timeupdate', async () => {
						const now = Date.now()

						const currentTime = playerRef.current.currentTime();
						const totalDuration = playerRef.current.duration();

						if (updateTimeCalculator.checkIfAboveThresholdDifference(now)) {
							updateTimeCalculator.setPreviousTime(now)
							await setProfileStreamPlayBackData(profileId, params.contentid, currentTime, totalDuration);
						}
						if (uploadTimeCalculator.checkIfAboveThresholdDifference(now)) {
							uploadTimeCalculator.setPreviousTime(now)
							await uploadUserPlayBackData(userId, profileId, params.contentid);
						}
					});

				});

				// add listener for seeking to stored playback position if it exist
				player_.on("loadedmetadata", async function () {

					applyTimelineListenersToPlayer(player_,props.streamData.stream.thumbnails_path);
					const profilePlayBackData = await getContentStreamPlayBackData(props.streamData.stream.content_id);
					console.log(profilePlayBackData, "no playback yet or pos 0");

					try {
						if (profilePlayBackData.playback !== null && profilePlayBackData.playback !== undefined) {
							player_.currentTime(profilePlayBackData.playback.position);

							switch (profilePlayBackData.origin) {
							case "local-storage":
								console.log(`Playback found in local-storage, continuing from ${formatVideoTime(profilePlayBackData.playback.position)} of ${formatVideoTime(player_.duration())}`);
								break;
							case "db":
								console.log(`Checking db, continuing from ${formatVideoTime(profilePlayBackData.playback.position)} of ${formatVideoTime(player_.duration())}`);
								break;
							default:
								console.log(`Using example, continuing from ${formatVideoTime(profilePlayBackData.playback.position)} of ${formatVideoTime(player_.duration())}`);
							}
						}
					} catch (e) {
						console.log(e);
					}
				});
			} else {
				try {
					player_.src({
						src: props.streamData && props.streamData.stream["hls_stream_path"].length > 0 ? `${process.env.REACT_APP_BASE_STREAM_URL}/${props.streamData.stream["hls_stream_path"]}`: "https://sample.vodobox.net/skate_phantom_flex_4k/skate_phantom_flex_4k.m3u8",
						type: "application/x-mpegURL", //video/mp4 application/x-mpegURL,
					});
				} catch (e) {
					console.log(e);
					// when its the default home page and there is no content_id passed in URL yet. basically for the display video (trending)
					if (!params.contentid) {
						console.log("no content id");
						//make request to get content. filter content where isDisplay == true and get the content_id.
					}
				}
			}
		} catch (e) {
			console.log(e);
		}
	}, [player]);

	// Dispose the player when the component will unmount
	useEffect(() => {
		const player = playerRef.current;

		return () => {
			if (player && !player.isDisposed()) {
				player.dispose();
				playerRef.current = null;
			}
		};
	}, [playerRef]);

	return (
		<div className={playerContainerName}>
			<div data-vjs-player>
				<div ref={videoRef} className="video-js" />
			</div>
			{isHovered && contentName && (
				<div className="movie-title-overlay">
					{contentName}
				</div>
			)}
		</div>
	);
};

export default AfrowatchVideoPlayer;