import { Link } from 'react-router-dom';
import '../Nav/NavBar.css'


const NavBar=(props)=>{

	return(
		<nav className="navbar navbar-expand-lg navbar-light bg-transparent">
			<a className="navbar-brand" href="/"><img className="img-fluid" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1619453419/AfroWatch/Afrowatch_Horizonal_Logo_-_White_cc4u6z.svg" alt=""/></a>
            
			{/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
				<span className="navbar-toggler-icon"></span>
			</button> */}

			<div className="collapse navbar-collapse" id="navbarSupportedContent">
				<ul className="navbar-nav ml-auto">
					<li className="nav-item mx-5">
						<Link onClick={() => props.routeBg('#2D132C')} className="nav-link px-3 signup" to="/signup">Sign Up</Link>
					</li>
                   
				</ul>
			</div>
		</nav>
	)
}

export default NavBar;