import React, { useState, useContext, useEffect} from 'react';
import {ThemeContext} from '../../context/ThemeContext';
import useThemeStyle from '../../hook/useThemeStyle';
import axios from 'axios'
import {GENRES} from '../constants'
import './Settings.css'
import { Alert, CircularProgress, IconButton, Snackbar } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const Settings=()=>{
	const axios_ = axios.create({baseURL: process.env.REACT_APP_BASE_URL})
	const currentUser = localStorage.getItem("currentUser");
	const profileId = localStorage.getItem("selected_prof_id");
	
	const [profileLoading, setProfileLoading] = useState(false);
	const [genrePreferencesList,setGenrePreferencesList] = useState([]);
	const [snackOpen, setSnackOpen] = useState(false);
	const [snackErrorOpen, setSnackErrorOpen] = useState(false);
	const [alertMessage, setAlertMessage] = useState("");
	const [alertErrorMessage, setAlertErrorMessage] = useState("");

	const {darkMode, toggleTheme} = useContext(ThemeContext)
	const settingStyle = useThemeStyle();

	const getGenrePreferences = async () => {
		const resp = await axios_.get(`/${currentUser}/user/${profileId}/genre-preferences`);
		const {data} =  resp.data;
		if(resp.data.success){
			let genrePreferences = data.genrePreferences;
			const genreList = GENRES.map(genre=>({genre,isPreference:genrePreferences.includes(genre)}));
			setGenrePreferencesList(genreList);
		}else{
			console.log(resp.data.message)
			const genreList = GENRES.map(genre=>({genre,isPreference:false}));
			setGenrePreferencesList(genreList);
		}
	}

	const handleGenrePreferencesChange = (e) => {
		const genresList = genrePreferencesList.map((genre,pos)=> pos === +e.target.id ? {...genre,isPreference:e.target.checked}:genre);
		setGenrePreferencesList(genresList);
	}

	const updateGenrePreferences = async(e) => {
		setProfileLoading(true);
		const genresList = genrePreferencesList.reduce((acc,genre)=>{
			genre.isPreference ? acc.push(genre.genre):null;
			return acc;
		},[]);

		const res = await axios_.post(`/${currentUser}/user/${profileId}/genre-preferences`,{genrePreferences:genresList});

		if(res.data.success){
			setSnackOpen(true);
			setAlertMessage("Genre preferences updated successfully");
		}else{
			setSnackErrorOpen(true);
			setAlertErrorMessage(res.data.message);
		}

		setProfileLoading(false);
	}


	const handleSnackBarClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setSnackOpen(false);
		setSnackErrorOpen(false);
	};

	const action = (
		<>
			<IconButton
				size="small"
				aria-label="close"
				color="inherit"
				onClick={handleSnackBarClose}
			>
				<CloseIcon fontSize="small" />
			</IconButton>
		</>
	);

	useEffect(()=>{
		getGenrePreferences();
	},[])

	return(
		<div> 
			<div style={settingStyle} className="mt-5 px-3 card settings-card py-4">
				<div className="row">
					<div className="col-md-3">
						<img className='settings_icon' src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1621111663/AfroWatch/Group_1_tsdu4n.svg" alt=""/>
					</div>

					<div className="col-md-9">
						<h6 style={settingStyle} className="settings-text">App Version</h6>
						<p style={settingStyle} className="">V1.0</p>
					</div>
				</div>
			</div>

            
			<div style={settingStyle} className="mt-4 px-3 card settings-card py-3">
				<div className="row">
					<div className="col-md-3">
						<img className='settings_icon' src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1621111663/AfroWatch/Group_2_byyliv.svg" alt=""/>
					</div>

					<div className="col-md-9">
						<h6 className="settings-text" style={settingStyle}>Theme Option</h6>
						<div className="d-flex align-items-center">
							<div className="d-flex align-items-center">
								<input checked={!darkMode} type="radio" name="theme" id="light" value="lightTheme" onChange={toggleTheme}  style={{cursor: 'pointer'}}/>
								<label className="settings-text-theme mx-4 mx-md-5" htmlFor="light" style={settingStyle}>Light</label>
							</div>
							<div className="d-flex align-items-center">
								<input type="radio" name="theme" id="dark" checked={darkMode} value="darkTheme" onChange={toggleTheme}  style={{cursor: 'pointer'}}/>
								<label className="settings-text-theme ml-4 mx-md-5"  htmlFor="dark" style={settingStyle}>Dark</label>
							</div>
						</div>

					</div>
				</div>
			</div>


			<div style={settingStyle} className="mt-5 px-3 card settings-card py-4 Settings-Checkbox-Card">
				<div className="row" >
					<div className="col-md-3">
						<img className='settings_icon' src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1621111663/AfroWatch/Group_1_tsdu4n.svg" alt=""/>
					</div>

					<div className="col-md-9">
						<div className='Settings-Checkbox-Header'>
							<h6 style={settingStyle} className="settings-text">Genre Preferences</h6>
							<button onClick={updateGenrePreferences} style={settingStyle} className="btn btn-danger btn-sm col-md-2 ml-md-5 my-1 mr-md-3" >
								{!profileLoading ? (
									"Update"
								) : (
									<CircularProgress style={{ width: "20px", height: "20px" }} color="inherit"/>
								)}
							</button>
						</div>

						<div className='Settings-Checkbox-Container'>
							{
								genrePreferencesList.map((genre,pos)=>(
									<div key={pos}>
										<label className="Settings-Checkbox">{genre.genre}
											<input id={pos} name={genre.genre} type='checkbox' defaultChecked={genre.isPreference} onChange={handleGenrePreferencesChange}/>
											<span className="Settings-Checkmark"></span>
										</label>
									</div>
								))
							}
						</div>


					</div>
				</div>
			</div>
			{snackOpen ? (
				<Snackbar
					open={snackOpen}
					autoHideDuration={8000}
					onClose={handleSnackBarClose}
					message={alertMessage}
					action={action}
					anchorOrigin={{ vertical: "top", horizontal: "right" }}
				>
					<Alert
						style={{ marginBottom: "1rem" }}
						icon={<CheckCircleOutlineIcon fontSize="inherit" />}
						sx={{ width: "100%" }}
						severity="success"
						onClose={handleSnackBarClose}
					>
						{alertMessage}
					</Alert>
				</Snackbar>
			) : null}
			{snackErrorOpen ? (
				<Snackbar
					open={snackErrorOpen}
					autoHideDuration={8000}
					onClose={handleSnackBarClose}
					message={alertErrorMessage}
					action={action}
					anchorOrigin={{ vertical: "top", horizontal: "right" }}
				>
					<Alert
						style={{ marginBottom: "1rem" }}
						icon={<ErrorIcon fontSize="inherit" />}
						sx={{ width: "100%" }}
						severity="error"
						onClose={handleSnackBarClose}
					>
						{alertErrorMessage}
					</Alert>
				</Snackbar>
			) : null}

           
			{/* <div style={settingStyle} className="mt-4 px-3 card settings-card py-3">
				<div className="row">
					<div className="col-md-3">
						<img className='settings_icon' src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1621111663/AfroWatch/Vector_qfs9gp.svg" alt=""/>
					</div>

					<div className="col-md-9">
						<h6 style={settingStyle} className="settings-text">Download over wifi or data</h6>
						<label className="switch">
							<input checked={isInitialToggle} type="checkbox" onChange={(e)=>{!isInitialToggle ? setToggle(true): setToggle(false)}} />
							<span className="slider round"></span>
						</label>
						<p></p>
						<p></p>
					</div>
				</div>
			</div> */}
               
		</div>
	)

}

export default Settings;