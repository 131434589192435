import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import "./Index.css";
import Footer from "../Footer/Footer";
import { axiosInstance } from "../../apiClient";
import { Link } from "react-router-dom";


const ResetPassword = () => {
	const [open, setOpen] = useState(false);
	const [message, setMessage] = useState("");
	const [newPassword, setNewPassword] = useState("")
	const [newPasswordRetyped, setNewPasswordRetyped] = useState("")
	const [showPassword, setShowPassword] = useState(false);
	const [isMobile, setIsMobile] = useState(false);

	const navigate = useNavigate();
	const email = localStorage.getItem('email-reset')

	const handlePasswordVisibility = () =>{
		setShowPassword(!showPassword);
	} 

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 760);
		};
		handleResize();

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		}
	}, [])

	const onSubmit = async (e) => {
		e.preventDefault()

		if(validateForm()){
			const res = await axiosInstance.post(`/auth/change-password`, { email, newPassword })
			console.log(res.data)

			if(res.data.success){
				setMessage("Password reset successfully!")
				setOpen(true);
				setTimeout(()=> navigate('/login'), 3000);
			}
		}
	};

	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setOpen(false);
	};

	const validateForm=()=>{
		if(newPassword !== newPasswordRetyped){
			setMessage("Passwords don't match")
			setOpen(true)
			return false;
		}

		if(newPassword === ""){
			setMessage("The input field is empty")
			setOpen(true)
			return false;
		}

		return true;
	}

	return (
		<div style={{ background: "#220B21" }}>
			<div  style={{
				position: "relative",
				minHeight: "100vh",
			}}
			className="h-100 w-100">
				<div className="custom-bg w-100 mx-auto">
					<div className="centered-container">
						<img src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1706140890/AfroWatch/forgot-password/Afrowatch-Logo--Vertical-White-font_umkurt.svg" alt="logo" className="mb-4 d-block m-auto" />
						<h5 className="text-white  mb-4 mt-4">Create New Password</h5>
						<div>
							<div className="mb-3 position-relative">
								<label htmlFor="newpassword" className="form-label text-white">
									New Password
								</label>
								<input
									type={showPassword ? "text" : "password"}
									className="form-control"
									id="newpassword"
									placeholder="********************"
									value={newPassword}
									onChange={(e)=> setNewPassword(e.target.value)}
								/>
								<span
									className="position-absolute eye-icon"
									style={{
										right: '10px', 
										top: '68%',
										transform: 'translateY(-50%)',
										cursor: 'pointer',
									}}
									onClick={handlePasswordVisibility}
								>
									{showPassword ? (
										<VisibilityIcon style={{ color: 'white' }} />
									) : (
										<VisibilityOffIcon style={{ color: 'white' }} />
									)}
								</span>
							</div>

							<div className="mb-5">
								<label htmlFor="retypepassword" className="form-label text-white">
									Retype Password
								</label>
								<input
									type="password"
									className="form-control"
									id="retypepassword"
									placeholder="********************"
									value={newPasswordRetyped}
									onChange={(e) => setNewPasswordRetyped(e.target.value)}
								/>
							</div>

							<div className="d-grid gap-2">
								<button
									className="btn btn-primary btn-block py-2"
									type="button"
									onClick={onSubmit}
								>
									Reset
								</button>
							</div>

							<div className="text-center py-4"> 
								<Link style={
									{
										fontStyle: 'normal',
										fontWeight: 'bold', 
										fontSize: '16px',
										lineHeight: '27px', 
										color: '#06BA83', 
										textDecoration:'underline'
									}
								} to="/login">Login</Link>
							</div>
						</div>
					</div>
				</div>
				
				<Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
					<Alert
						onClose={handleClose}
						severity="success"
						variant="filled"
						sx={{ width: "100%" }}
					>
						{message}
					</Alert>
				</Snackbar>
			</div>
			
			{!isMobile && <Footer style={{ position: "absolute", bottom: 0, width: "100%" }} />}

		</div>
	);
};

export default ResetPassword;
