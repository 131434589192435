import axios from 'axios';

// Create an Axios instance with a base URL
const axios_ = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
});

// Utility function for fetching user data
const getUser = async () => {
	try {
		// Retrieve `currentUser` from localStorage or a similar source
		const currentUser = localStorage.getItem('currentUser');
		if (!currentUser) {
			console.error('No current user found.');
			return null;
		}

		const res = await axios_.get(`/user/${currentUser}`, {
			headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		});

		if (res.data.success) {
			return res.data.data; // Return user data directly
		} else {
			console.error('Error message from server:', res.data.message);
			return null;
		}
	} catch (error) {
		console.error('An error occurred while fetching user data:', error);
		return null;
	}
};

export default getUser;
