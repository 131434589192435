import React from "react";
import $ from 'jquery'

const PlayButton =()=>{

	const windowWidth = $(window).width()

	return(
		<img style={{
			position: "absolute",
			left: "49%",
			top: "50%",
			width: windowWidth > 600 ? "30px" : "10px",
			height: windowWidth > 600 ? "50px" : "40px",
			transform: "translate(-50%, -50%)",
			objectFit: "contain"
		}}
			
		src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620946412/AfroWatch/Component_3_zf2imv.svg"
		/>
	)
}

export default PlayButton;