import React, { useEffect, useState } from "react";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import MessageIcon from "@mui/icons-material/Message";
import ShareIcon from "@mui/icons-material/Share";
import "./VideoSidebar.css";
import SimpleDialog from "./CommentDialog";
import ShareDialog from "./shareDialog";
import { axiosInstance } from "../../apiClient";

const VideoSidebar = ({ likes, shares, comments, url, contentId }) => {
	const [liked, setLiked] = useState(false);
	const [showMessage, setShowMessage] = useState(false);
	const [showShare, setShowShare] = useState(false);

	const [likeCount, setLikeCount] = useState(likes);

	const [anchorEl, setAnchorEl] = useState(null);
	const [shareanchorEl, setShareAnchorEl] = useState(null);

	const [user, setUser] = useState({})
	const currentUser = localStorage.getItem('currentUser');

	const [profile, setProfile] = useState({})
	const currentProfile = localStorage.getItem("selected_prof_id");

	const handleClick = (event) => {
		setShowMessage(!showMessage);
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setShowMessage(false);
		setAnchorEl(null);
		setShareAnchorEl(null);
		setShowShare(false);
	};

	const handleShare = (event) => {
		setShowShare(!showShare);
		setShareAnchorEl(event.currentTarget);
	};

	const setLikes = async (l, updatedLiked)=>{
		const response = await axiosInstance.put(`/content/afclips/${contentId}/likes/${currentUser}/${currentProfile}`, { likes: l, liked: updatedLiked })
		const { data }= response.data
		if(response.data.status === 200){
			console.log(data)
			setLikeCount(data.likeCount)
		}
	}

	const handleLike = async () => {
		const updatedLiked = !liked; 
		setLiked(updatedLiked);

		const newLikeCount = updatedLiked ? likeCount + 1 : likeCount - 1;
		setLikeCount(newLikeCount);

		await setLikes(newLikeCount, updatedLiked);
	};

	const getUser = async()=>{
		const resp = await axiosInstance.get(`/user/${currentUser}`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }})
		const {data} =  resp.data;
		if(resp.data.success){
			setUser(data)
		}
	}

	useEffect(() => {
		getUser();
	}, []);

	useEffect(() => {
		if (user && user.profiles && user.profiles.length > 0) {
			const foundProfile = user.profiles.find((profile) => profile._id === currentProfile);
			setProfile(foundProfile);
		}
	}, [user])

	useEffect(() => {
		if (profile && profile.likes) {
			// console.log(profile.likes.includes(contentId), contentId);
			console.log(profile.likes && profile.likes.includes(contentId))

			if(profile.likes && profile.likes.includes(contentId)){
				setLiked(true)
			}
		}
	}, [profile])

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : '';

	const shareOpen = Boolean(shareanchorEl);
	const shareId = shareOpen ? 'simple-popover' : '';

	return (
		<div className="videoSidebar">
			{/* Likes */}
			<div className="videoSidebar__button">
				{
					<div
						style={{
							width: '50px',
							height: '50px',
							borderRadius: '50%',
							backgroundColor: '#512350',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							cursor: 'pointer',
						}}
						onClick={(e) => handleLike()}
					>
						{liked ? 
							<FavoriteIcon fontSize="large" sx={{ color: 'red' }}/> 
							: 
							<FavoriteBorderIcon fontSize="large" sx={{ color: 'white' }} /> 
						} 
					</div>
				}
				<p>{likeCount}</p>
			</div>
			{/* Comments */}
			<div className="videoSidebar__button">
				{showMessage && (
					<SimpleDialog
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
						id={id}
						contentId={contentId}
						comments={comments}
					/>
				)}
				<div
					style={{
						width: '50px',
						height: '50px',
						borderRadius: '50%',
						backgroundColor: '#512350',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						cursor: 'pointer',
					}}
					onClick={handleClick}
				>
					<MessageIcon fontSize="large" />
				</div>
				<p>{comments.length}</p>
			</div>

			{/* Share */}
			<div className="videoSidebar__button">
				{showShare && (
					<ShareDialog
						anchorEl={shareanchorEl}
						open={shareOpen}
						onClose={handleClose}
						id={shareId}
						url={url}
					/>
				)}
				<div
					style={{
						width: '50px',
						height: '50px',
						borderRadius: '50%',
						backgroundColor: '#512350',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						cursor: 'pointer',
					}}
					onClick={handleShare}
				>
					<ShareIcon fontSize="large" />
				</div>
				<p>{shares}</p>
			</div>
		</div>
	);
};

export default VideoSidebar;
