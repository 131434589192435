import {useEffect, useState} from 'react';
import { useNavigate, useLocation} from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios'
import {loadStripe} from '@stripe/stripe-js';
import BottomNav from "../Nav/BottomNav";
import {Elements} from '@stripe/react-stripe-js';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import NavBarSearch from '../Nav/TopNavBarV2';
import NavBar from '../Nav/NavBar';
import Footer from '../Footer/Footer';
import ReactGA from 'react-ga4'
import Pay from './PayElement';
import './PricingPlan.css';
import './element.css';
import { SUBSCRIPTION_INCOMPLETE } from '../constants';

const StartYourPlan=(props)=>{ 
	const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK_KEY);
	const monthNames = ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November","December"];
	const [user, setUser] = useState({email:"", name:"", firstName:"", lastName:"", city:'', country:'', state:'', postcode:'', lineOne:'', currentPlan:null, profiles:[]})
	const [clientSecret_, setSecret] = useState("")
	const [trialEnd, setTrial] = useState("")
	const [paySuccess, setSuccess] = useState(false)
	const [alert, setAlert] = useState({message:"", isAlert:false});
	const [errorAlert, setErrorAlert] = useState({message:"", isAlert:false})
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);	
	const [subloading, setSubLoading] = useState(false);	

	const axios_ = axios.create({baseURL: process.env.REACT_APP_BASE_URL});

	const navigate = useNavigate()

	const [isFooter, setFooter] = useState({
		footerActive: true,
		bottomNavActive: false,
	});


	const location = useLocation();
	const planName = location.state?.planName || 'Default Plan';
	const price = location.state?.price || 4.99;
	const nollyWood =  location.state?.nollyWood || true;
	const gollyWood =  location.state?.gollyWood || true;
	const highDefinition =  location.state?.highDefinition|| true;
	const devices =  location.state?.devices || 1;
	const screens =  location.state?.screens || 1;
	const ultraHD =  location.state?.ultraHD || false;
	const priceId_ = location.state?.priceId

	const currentUser = localStorage.getItem('currentUser')
	const region  = localStorage.getItem("region")
	const profileId = localStorage.getItem("selected_prof_id")
	let	_region = region ? JSON.parse(region) : {};

	let date =()=>{
		const currentDay = new Date()
		return currentDay
	}

	const getUser= async ()=>{
		const resp = await axios_.get(`/user/${currentUser}`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }});
		const {data} =  resp.data;

		if(resp.data.success){
			if(data === null){
				setUser({...user, email:'', name:"", city:'', country:'', state:'', postcode:'', lineOne:''})

			}else if(data.fullName.length > 0 && data.fullName !== undefined){
				setUser({...user, name: data.fullName, firstName:data.fullName.split(" ")[0], lastName:data.fullName.split(" ")[1], email: data.email, city:data.billing.city, country:data.billing.country, state:data.billing.state, 
					postcode:data.billing.postcode, lineOne:data.billing.addressLineOne, currentPlan:data.currentPlan, profiles:data.profiles
				})
			}else{
				setUser({...user, email:'', name:"", city:'', country:'', state:'', postcode:'', lineOne:''})
			}
		}else{
			console.log(resp.data.message)
		}
	}

	const createStripeCustomer= async()=>{
		const res = await axios_.post(`/create-stripe-customer/${currentUser}`, user)
		if(res.status === 200){
			//if the stripeCustomerId already exists in the db it gets that info. if not it gets the immediate stripe customer info after creating a new stripe customer object
			console.log(`customer ${res.data.customer.id || res.data.customer._id}:[${res.data.customer.name || res.data.customer.fullName}, ${res.data.customer.stripeCustomerId}]`)
			return res.data.customer.id || res.data.customer.stripeCustomerId
		}
	}

	/**Subscribe */
	const subscribe = async(event)=>{
		setSubLoading(true);
		event.preventDefault()
		let customerId_
		if(user.name.length > 0){
			customerId_ = await createStripeCustomer()
		}
		const userId = localStorage.getItem('currentUser')
       
		try{
			localStorage.setItem("profile_id", user.profiles[0].profile_id)
			localStorage.setItem("profile_name", user.profiles[0].profile_name)
            
			let subscription = { priceId:priceId_, customerId:customerId_ }

			const res = await axios_.post(`/create-subscription/${userId}`, subscription)
			if(res.status === 200){
				setSubLoading(false);
				//alert created subscription successfully, inactive and awaiting payment
				setSecret(res.data.clientSecret)

			}else {
				setErrorAlert({message:'An error occured. Click the start button', isAlert:true})
			}
		}catch(e){
			setErrorAlert({message:'An error occured.', isAlert:true});
			console.log({e})
		}

		ReactGA.event({
			category: 'Plans & Subscription',
			action: `Clicked the subscribe button for ${planName} with price ${price}`
		});
	}

	const cancelSubcription= async ()=>{
		handleClose();
		setLoading(true);
		const res = await axios_.post(`/cancel-subscription/${currentUser}`, { currentPlan: { subscriptionId: user.currentPlan.subscriptionId }})
		if(res.status === 200){
			setLoading(false);
			setAlert({message:res.data.message, isAlert:true});
			setTimeout(()=>{
				navigate(`/profile/${currentUser}`);
			}, 3000)	
		} else {
			setErrorAlert({message:res.data.message, isAlert:true})
		}
	}

	const handleDownloadPolicy = (e) => {
		e.preventDefault()
		const fileUrl =  'https://res.cloudinary.com/www-softroniiks-com/image/upload/v1690406407/AfroWatch/files/policy_iv2scz.pdf';
		const fileName = 'Afrowatch Privacy Policy.pdf';
    
		const link = document.createElement('a');
		link.href = fileUrl;
		link.download = fileName;
		link.target ='_blank'
    
		document.body.appendChild(link);
    
		link.click();
    
		document.body.removeChild(link);
	};

	const handleDownloadTerms = (e) => {
		e.preventDefault()
		const fileUrl =  'https://res.cloudinary.com/www-softroniiks-com/image/upload/v1690489707/AfroWatch/files/terms-of-use_gihwge.pdf';
		const fileName = 'Afrowatch Privacy Policy.pdf';
    
		const link = document.createElement('a');
		link.href = fileUrl;
		link.download = fileName;
		link.target ='_blank'
    
		document.body.appendChild(link);
    
		link.click();
    
		document.body.removeChild(link);
	}

	useEffect(()=>{
		getUser()
	},[])

	const updateUser= async ()=>{
		const res = await axios_.put(`${currentUser}/user`, {firstName:user.firstName.trim(), lastName:user.lastName.trim(), email:user.email})
		try{
			if(res.data.success){
				setAlert({...alert, message:res.data.message, isAlert:true})
			}else{
				setAlert({...alert, message:res.data.message, isAlert:true})
			}
		}catch(e){
			console.error(err)
		}
	}

	const toSignUpComp=()=>{
		navigate('/signup', {state: {email: user.email, firstName:user.firstName, lastName:user.lastName}})
		// setAlert({...alert, message:"Get notified on when we launch!", isAlert:true})
		// setTimeout(() => {
		// 	navigate('/')
		// }, 1000);
	}

	const handleClickOpen = () => {
		setOpen(true);
	};
	
	const handleClose = () => {
		setOpen(false);
	};

	const windowWidth = $(window).width();

	// Appearance customization object
	const appearance = {
		theme: 'flat',
		variables: {
			colorPrimary: '#ffffff',   // Primary color
			colorBackground: '#333333', // Background color for form elements
			colorText: '#ffffff',      // Text color inside the inputs
			colorDanger: '#ff6b6b',    // Error text color
			fontFamily: 'Poppins, sans-serif', // Customize font
			spacingUnit: '4px', // Adjust spacing inside the form
			borderRadius: '8px', // Rounder edges
			tabIconSelectedColor: '#fff'
		},
		rules: {
			'.Label': {
				color: '#ffffff', // Force label color to white
				fontSize: '14px',
				fontWeight: 'semi-bold',
			},
			'.Input': {
				color: '#ffffff', // Input text color set to white
				backgroundColor: '#331832B2', // Ensure input background matches the form background
				borderColor: '#ffffff', // Input border color
			},
			'::placeholder': {
				color: '#cccccc', // Placeholder text color set to light gray
			},
			'.Error': {
				color: '#ff6b6b', // Error text color
			},
			'.Tab, .Block, .PickerItem--selected': {
				backgroundColor: '#331832B2',
				boxShadow: 'none'
			},
			'.Tab': {
				transition: 'none'
			},
			'.Tab:hover': {
				backgroundColor: '#1F0F25'
			},
			'.Tab--selected, .Tab--selected:focus, .Tab--selected:hover': {
				color: 'var(--colorText)',
				backgroundColor: '#1F0F25'
			},
			'.Tab:focus, .Tab--selected:focus': {
				boxShadow:  'none',
				outline: 'none'
			},
			'.Tab:focus-visible': {
				outline: 'var(--focusOutline)'
			},
			'.PickerItem': {
				backgroundColor: '#331832',
				boxShadow: 'none',
				transition: 'none'
			},
			'.PickerItem:hover': {
				backgroundColor: '#1F0F25'
			},
			'.PickerItem--highlight': {
				outline: '1px solid blue'
			},
			'.PickerItem--selected:hover': {
				backgroundColor: '#dfdfdf'
			}
		},
	};

	//Determine Mobile or not
	useEffect(()=>{
		if($(window).width() <= 600){
			setFooter({...isFooter, bottomNavActive:true, footerActive:false})
		} else {
			setFooter({...isFooter, bottomNavActive:false, footerActive:true})
		}
	},[])
	return(
		<div className="bg-pricingplan">
			{ windowWidth > 600 ? <NavBarSearch region={_region[profileId]}/> :<NavBar/> }

			{/* {alert.isAlert ? <Toast message={alert.message} paySuccess={alert.isAlert}/> : null} */}

			{ alert.isAlert ?
				<Snackbar open={alert.isAlert} autoHideDuration={6000} onClose={()=> setAlert({isAlert:false})}  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
					<Alert style={{marginBottom: '1rem'}} 
						icon={<CheckCircleOutlineIcon fontSize="inherit" />} 	
						sx={{ width: '100%' }} severity="success"
						onClose={()=> setAlert({isAlert:false})}>
						{`${alert.message}`}
					</Alert>
				</Snackbar> : null
			}

			{ errorAlert.isAlert ? 
				<Snackbar open={errorAlert.isAlert} autoHideDuration={6000} onClose={()=> setAlert({isAlert:false})}  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
					<Alert style={{marginBottom: '1rem'}} icon={<CheckCircleOutlineIcon fontSize="inherit" />} 	sx={{ width: '100%' }} severity="error"
						onClose={()=> setAlert({isAlert:false})}>
						{`${errorAlert.message}`}
					</Alert>
				</Snackbar> : null
			}

			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<h1 className="pricing-plan-text mt-5">Start Your Plan Today</h1>
						<div className="d-flex">
							<div className="light-pink-water mt-md-2"></div> <div className="light-pink-water-two ml-md-2 mt-md-2"></div>
						</div>
						<p style={{fontSize:'16px'}} className="pricing-p mt-5">Start your {planName} plan today and enjoy access to African Animation on <br/>{screens} devices.</p>
					</div>
				</div>
			</div>    

			<div className="container"> 
				{/* <form id="payment-form" className="" action="" method="post"> */}
				<div className="row mt-5">
					<div className="col-md-8 py-4">
						<div className="row d-flex">
							<div className="col-md-6">
								<input className="form-control input-plan mt-md-5 my-2 my-md-0" onChange={(e)=>{setUser({...user, firstName:e.target.value})}} value={user.firstName} name='firstName' type="text" placeholder="First name" required/>
								<input className="form-control input-plan mt-md-4 my-2 my-md-0" onChange={(e)=>{setUser({...user, email:e.target.value})}} value={user.email} type="email" name='email' placeholder="email" required/>
							</div>

							<div className="col-md-6">
								<input className="form-control input-plan my-md-5 ml-md-4" onChange={(e)=>{setUser({...user, lastName:e.target.value})}} value={user.lastName} type="text" placeholder="Last name"required/>
								{currentUser ?  <button style={{right:-20}} className="edit-prof-btn py-1 px-5 ml-3 mt-5" onClick={updateUser}>Update</button> : 
									<button style={{right:-20}} className="edit-prof-btn py-1 px-5 ml-3 mt-5" onClick={toSignUpComp}>Start</button>}
							</div>
						</div>

						{/* {console.log(clientSecret_)} */}

						{clientSecret_.length > 0 ? 
							<div style={{color: 'white'}}>
								<div className="row">
									<div className="col-md-12">
										<h1 className="pricing-plan-text mt-5">Card Details</h1>
										<div className="d-flex">
											<div className="light-pink-water mt-md-2"></div> <div className="light-pink-water-two ml-md-2 mt-md-2"></div>
										</div>
										<p style={{fontSize:'16px'}} className="pricing-p mt-5">Input a valid credit or debit card to subscribe now and enjoy unlimited access to african movies, series and animation </p>
									</div>
								</div>

								{/* Stripe or Paystack inbuilt UI */}
								<Elements stripe={stripePromise} options={{clientSecret: clientSecret_, appearance}} >
									<Pay/>
								</Elements> </div> : null
						}

						{paySuccess ? <div>Your 30 day free trial has started and will end on {trialEnd}!</div>: null}
					</div>

					<div className="col-md-4">
						<div className="card price-subscribe-card">
							<h1 className="start-plan-name text-center py-3">{planName}</h1>
							<p className="start-plan-price">${price} per month</p>
							<hr className="line-break"/>
							<div className="card-body">
								<div className="start-plan-list">
									<li className="py-2">Nigeria {nollyWood ? <img className="mt-1 start-plan-tick" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg" alt=""/>:<img className="mt-1 start-plan-tick" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620909774/AfroWatch/gug5ptexxawuux2nfvq6.svg" alt=""/>}</li>
									<li className="py-2">Ghana {gollyWood ? <img className="mt-1 start-plan-tick" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg" alt=""/>:<img className="mt-1 start-plan-tick" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620909774/AfroWatch/gug5ptexxawuux2nfvq6.svg" alt=""/>}</li>
									<li className="py-2">South Africa {gollyWood ?<img className="mt-1 start-plan-tick" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg" alt=""/>:<img className="mt-1 start-plan-tick" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620909774/AfroWatch/gug5ptexxawuux2nfvq6.svg" alt=""/>}</li>
									<li className="py-2">Devices <span className="start-plan-tick">{devices } </span></li>
									<li className="py-2">Screens <span className="start-plan-tick">{screens} </span></li>
									<li className="py-2">High Definition {highDefinition ? <img className="mt-1 start-plan-tick" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg" alt=""/>:<img className="mt-1 start-plan-tick" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620909774/AfroWatch/gug5ptexxawuux2nfvq6.svg" alt=""/>}</li>
									<li className="py-2">Ultra HD{ultraHD ? <img className="mt-1 start-plan-tick" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg" alt=""/>:<img className="mt-1 start-plan-tick" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620909774/AfroWatch/gug5ptexxawuux2nfvq6.svg" alt=""/>}</li>
									<li className="py-2">Watch on your Laptop, tablet or phone <img className="mt-1 start-plan-tick" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg" alt=""/></li>
								</div>
								<hr className="line-break"/>

								<p className="start-plan-list">Enjoy Plan (per Month) <span className="start-plan-tick">${price}</span></p>
								<small className="billed">Total billed on {`${date().getDate()} ${monthNames[date().getMonth()]} ${date().getUTCFullYear()}`}</small><br/>
                               
								{user.currentPlan && user.currentPlan?.status == SUBSCRIPTION_INCOMPLETE || user.currentPlan?.status == null ? 
									<button className="subscribe-btn py-2 my-3" type="submit" onClick={subscribe} >
										{
											!subloading ? 'Subscribe Now' : <CircularProgress style={{ width: '20px', height: '20px'}} color="inherit"/>
										}
									</button> 
									: user.currentPlan.priceId === location.state.priceId ? 
										(user.currentPlan.status === 'active' ?
											<button className="subscribe-btn py-2 my-3" type="submit" onClick={cancelSubcription}>Cancel Plan</button> 
											: 
											<div className='text-center'>
												<div className='text-white py-2'>Your plan ends: { new Date(user.currentPlan.periodEndDate).toDateString()}</div>
												<button className="subscribe-btn py-2 my-3" type="submit" onClick={subscribe}>
													Click to Re-subscribe
												</button> 
												<span className='text-white'>OR</span>
												<div className="subscribe-link" type="submit" onClick={handleClickOpen}>Change Plan</div>
											</div>
										)
										:
										<button className="subscribe-btn py-2 my-3" type="submit" onClick={handleClickOpen}>Change Plan</button>
								}
								<p className="py-3 text-center text-white">By Proceeding you agree to the <span ><a className='terms' href='/terms-of-use' 
									onClick={handleDownloadTerms}>Terms of Use</a></span> and <span><a href='/privacy' className='terms' onClick={handleDownloadPolicy}>privacy policy</a></span></p>
							</div>
                           
						</div>
					</div>
                    
				</div>
				{/* </form> */}
				{
					open &&
					<Dialog
						open={open}
						onClose={handleClose}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<DialogTitle id="alert-dialog-title">
							{"Change Subscription"}
						</DialogTitle>
						<DialogContent>
							<Alert severity="info">Your existing subscription will be cancelled to change plan. <br/> </Alert>
						</DialogContent>
						<DialogActions>
							<Button className="cancel-dialog" onClick={handleClose}>Close</Button>
							<Button className="cancel-dialog" onClick={cancelSubcription} autoFocus>
								{
									!loading ?'Proceed' : <CircularProgress style={{ width: '20px', height: '20px'}} color="inherit"/>
								}
							</Button>
						</DialogActions>
					</Dialog>
				}
			</div>
			{ isFooter.footerActive ? <Footer /> :  <BottomNav className="d-block d-md-none d-sm-block" /> }
		</div>
	)
}

export default StartYourPlan;