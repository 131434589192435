import React, { useRef, useState, useEffect } from "react";
import Hls from "hls.js";
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
//import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded';
import IconButton from '@mui/material/IconButton';
import VideoFooter from './VideoFooter';
import VideoSidebar from "./VideoSidebar";
import "./Video.css";

function Video({ url, animators, description, audio, likes, comments, shares, contentId }) {
	const [playing, setPlaying] = useState(false);
	//const [replay, setReplay] = useState(false);
	const videoRef = useRef(null);
	const progressRef = useRef(null);
	const [progress, setProgress] = useState(0);
	let hls;

	useEffect(() => {
		const video = videoRef.current;
		if (Hls.isSupported()) {
			hls = new Hls();
			hls.loadSource(`${process.env.REACT_APP_BASE_STREAM_URL}/${url}`);
			hls.attachMedia(video);
		} else if (video.canPlayType("application/x-mpegURL")) {
			video.src = url;
		}

		// const handleEnded = () => {
		// 	videoRef.current.pause();
		// 	setPlaying(true);
		// };

		//video.addEventListener('ended', handleEnded);
    
		return () => {
			if (hls) {
				hls.destroy();
			}
			//video.removeEventListener('ended', handleEnded);
		};
	}, [url]);

	const onVideoPress = () => {
		if (playing && videoRef.current) {
			videoRef.current.pause();
			setPlaying(false);
		} else {
			videoRef.current.play();
			setPlaying(true);
		}
	};

	// const onReplay = () => {
	// 	if (replay  && progressRef.current) {
	// 		videoRef.current.pause();
	// 		setPlaying(false);
	// 	} else {
	// 		videoRef.current.play();
	// 		setPlaying(true);
	// 	}
	// };

	const handleTimeUpdate = () => {
		const video = videoRef.current;
		const currentProgress = (video.currentTime / video.duration) * 100;
		setProgress(currentProgress);
	};

	const handleProgressClick = (e) => {
		const video = videoRef.current;
		const progressBar = progressRef.current;
		const rect = progressBar.getBoundingClientRect();
		const offsetX = e.clientX - rect.left;
		const newTime = (offsetX / progressBar.offsetWidth) * video.duration;
		video.currentTime = newTime;
	};

	useEffect(() => {
		const video = videoRef.current;
		video.addEventListener('timeupdate', handleTimeUpdate);

		if(progress === 0 && progressRef.current){
			videoRef.current.pause();
			setPlaying(false);
			//setReplay(true);
		}

		return () => {
			video.removeEventListener('timeupdate', handleTimeUpdate);
		};
	}, [progress]);

	return (
		<>
			<div className="video">
				<video className="video__player" onClick={onVideoPress} ref={videoRef} controls={false} autoPlay loop />
				<div
					ref={progressRef}
					onClick={handleProgressClick}
					className="progressvideo"
				>
					<div
						style={{
							width: `${progress}%`,
							height: '100%',
							backgroundColor: '#C92941',
						}}
					/>
				</div>
				{
					!playing && (
						<div className="play-button-container">
							<IconButton aria-label="play" onClick={onVideoPress} className="play-button">
								<PlayArrowRoundedIcon style={{color: 'white', fontSize: '2.5rem'}} />
							</IconButton>
						</div>
					)
				}
				{/* {
					replay &&  !playing &&  (
						<div className="play-button-container">
							<IconButton aria-label="play" onClick={onVideoPress} className="play-button">
								<ReplayRoundedIcon style={{color: 'white', fontSize: '3rem'}} />
							</IconButton>
						</div>
					)
				} */}

				{/* The VideoSidebar looks good on mobile but the actual video needs to move up */}
				<VideoSidebar likes={likes} comments={comments} contentId={contentId}/>
				<VideoFooter animators={animators} description={description} audio={audio} />
			</div>
		</>
	);
}

export default Video;