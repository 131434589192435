import * as React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import './dialog.css';




function ShareDialog(props) {
	const { onClose, open, id, anchorEl, url } = props;

	const handleClose = () => {
		onClose();
	};


	const [opensnackbar, setOpenSnackbar] = React.useState(false);

  
	const handleSanckbarClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
  
		setOpenSnackbar(false);
	};

	const copyToClipboard = () => {
		navigator.clipboard.writeText(url)
			.then(() => {
				console.log('URL copied to clipboard:', url);
				setOpenSnackbar(true);
				// handleClose();
				// Optionally, you can show a success message to the user
			})
			.catch((error) => {
				console.error('Failed to copy URL to clipboard:', error);
				// Optionally, you can show an error message to the user
			});
	};
	const handleWhatsaAppClick = () => {
		// Construct the WhatsApp web API URL
		const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(url)}`;
    
		// Open the URL in a new tab
		window.open(whatsappUrl, '_blank');
	};

	const handleInstagramClick = () => {
		const instagramUrl = `https://www.instagram.com/share?url=${encodeURIComponent(url)}`;
		window.open(instagramUrl, '_blank');
	};

	const handleTwitterClick = () => {
		const twitterUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent('watch this short video from Afro watch')}`;
		window.open(twitterUrl, '_blank');
	};
    
    

	return (
		<>
			<Popover onClose={handleClose} open={open}  
				id={id}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				sx={{
					borderRadius: '1rem'
				}}
			>
		
				<List sx={{ pt: 0 }}>
					<ListItem disableGutters>
						<ListItemButton onClick={() => copyToClipboard()}>
							<ListItemAvatar>
								<Avatar sx={{ bgcolor: '#512350', color: '#ffffff' }}>
									<LinkOutlinedIcon />
								</Avatar>
							</ListItemAvatar>
							<div style={{display: 'block'}}>
								<Typography style={{fontSize: '1rem', fontWeight: 'bold', color: '#293242', fontFamily: `'Poppins', sans-serif`}}>Copy Link</Typography>

							</div>
						</ListItemButton>
					</ListItem>
					<ListItem disableGutters>
						<ListItemButton onClick={() => handleWhatsaAppClick()}>
							<ListItemAvatar>
							
								<img src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1715293523/AfroWatch/whatsapp-svgrepo-com_tsflts.svg" className='img-fluid' style={{height: '2rem', width: '2rem'}} alt="whatsapp-icon" />
							
							</ListItemAvatar>
							<div style={{display: 'block'}}>
								<Typography style={{fontSize: '1rem', fontWeight: 'bold', color: '#293242', fontFamily: `'Poppins', sans-serif`}}>Share to WhatsApp</Typography>

							</div>
						</ListItemButton>
					</ListItem>
					<ListItem disableGutters>
						<ListItemButton onClick={() => handleInstagramClick()}>
							<ListItemAvatar>
							
								<img src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1715295135/AfroWatch/instagram-1-svgrepo-com_ltrd0f.svg" className='img-fluid' style={{height: '2rem', width: '2rem'}} alt="instsgram-icon" />
							
							</ListItemAvatar>
							<div style={{display: 'block'}}>
								<Typography style={{fontSize: '1rem', fontWeight: 'bold', color: '#293242',  fontFamily: `'Poppins', sans-serif`}}>Share to Instagram</Typography>

							</div>
						</ListItemButton>
					</ListItem>
					<ListItem disableGutters>
						<ListItemButton onClick={() => handleTwitterClick()}>
							<ListItemAvatar>
							
								<img src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1715295135/AfroWatch/icons8-twitterx_qk9h2x.svg" className='img-fluid' style={{height: '2rem', width: '2rem'}} alt="x-icon" />
							
							</ListItemAvatar>
							<div style={{display: 'block'}}>
								<Typography style={{fontSize: '1rem', fontWeight: 'bold', color: '#293242',  fontFamily: `'Poppins', sans-serif`}}>Share to X</Typography>

							</div>
						</ListItemButton>
					</ListItem>
				</List>
			</Popover>
			<Snackbar open={opensnackbar} autoHideDuration={3000} onClose={handleSanckbarClose}>
				<Alert
					onClose={handleSanckbarClose}
					sx={{ width: '100%' }}
				>
                        URL copied to clipboard
				</Alert>
			</Snackbar>

		</>
	);
}

ShareDialog.propTypes = {
	onClose: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
	id: PropTypes.bool.isRequired,
	anchorEl: PropTypes.any,
	url: PropTypes.string,
};


export default ShareDialog;