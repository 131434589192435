import * as React from 'react';
import {useState, useEffect} from 'react'
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import CloseIcon from '@mui/icons-material/Close';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import './dialog.css';
import { axiosInstance } from '../../apiClient';

const profileId = localStorage.getItem("selected_prod_id")
const profileName = localStorage.getItem("profile_name")

function SimpleDialog(props) {
	const { onClose, open, id, anchorEl, contentId, comments } = props;

	const [text, setText] = useState('');
	const [isIconButton, setIconButton] = useState(true);
	const [_comments, setComments] = useState(comments) //initial comments from props

	const handleClose = () => {
		onClose();
	};

	const handleListItemClick = () => {
		onClose();
	};

	const submitComment= async ()=>{
		const comment = {
			author:profileName, 
			text:text, 
			dateCreated:Date.now()
		}

		const response = await axiosInstance.post(`/content/afclips/${contentId}/comments`, comment)
		console.log(response.data)
		if(response.data.status === 200){
			setComments([..._comments, response.data.comment]); 
			setText('')
		}else{
			//Toast error
		}
	}

	useEffect(()=>{
		if(text === "" || text.length < 1){
			setIconButton(!isIconButton)
		}else{
			setIconButton(true)
		}
	},[text])

	return (
		<Popover onClose={handleClose} open={open}  
			id={id}
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: 'center',
				horizontal: 'right',
			}}
			transformOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
			sx={{
				borderRadius: '1rem'
			}}
			style={{
				height:'300px'
			}}
		>
            
			<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '15px'}}>
				<Typography style={{color:'#22262E', fontSize: '16px', fontFamily: `'Poppins', sans-serif`}}>Comments</Typography>
				<IconButton
					aria-label="close"
					onClick={handleClose}
					sx={{
						color: '#22262E',
					}}
				>
					<CloseIcon />
				</IconButton>
			</div>
		
			<List sx={{ pt: 0 }}>
				{
					_comments.length > 0 && _comments.map((item) => (
						<ListItem disableGutters key={item.author}>
							<ListItemButton onClick={() => handleListItemClick(item.author)}>
								<ListItemAvatar>
									<Avatar sx={{ bgcolor: 'rgb(81, 35, 80)' }}  style={{height:'22px', width:'22px'}}>
										<PersonOutlineOutlinedIcon  style={{height:'12px', width:'12px'}}/>
									</Avatar>
								</ListItemAvatar>

								<div style={{display: 'block', background:''}}>
									<Typography style={{color: '#293242', fontSize: '12px',  fontFamily: `'Poppins', sans-serif`}}>{item.author}</Typography>
									<Typography style={{color: '#293242', fontSize: '8px',  fontFamily: `'Poppins', sans-serif`}}>{item.dateCreated ? new Date(item.dateCreated).toUTCString() : null}</Typography>
									<Typography style={{fontSize: '14px', fontWeight: 'bold', fontFamily: `'Poppins', sans-serif`, color:'black'}}>{item.text}</Typography>
								</div>
							</ListItemButton>
						</ListItem>
					))
				}
				<ListItem disableGutters>
					<FormControl sx={{ m: 1, width: '30ch' }} variant="none" style={{border: 0}}>
						<OutlinedInput
							autocomplete="off"
							autoFocus="false"
							id="outlined-adornment-coment"
							endAdornment={
								<InputAdornment position="end">
									{isIconButton ? 
										<IconButton>
											<SendRoundedIcon onClick={submitComment} className={'rotate-up my-1 ml-4'} />
										</IconButton> : null 
									}
								</InputAdornment>
							}
							aria-describedby="outlined-comment-helper-text"
							placeholder='Add a comment'
							inputProps={{'aria-label': 'comment',}}
							sx={{borderColor: '#98A2B3', borderRadius: '12px', color: '#98A2B3'}}
							value={text}
							onChange={(e)=>setText(e.target.value)}
						/>
					</FormControl>
				</ListItem>
			</List>
		</Popover>
	);
}

SimpleDialog.propTypes = {
	onClose: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
	id: PropTypes.bool.isRequired,
	anchorEl: PropTypes.any,
};


export default SimpleDialog;