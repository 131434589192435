import React, {useState, useEffect} from 'react'
import NavBar from '../Nav/NavBar';
import Footer from '../Footer/Footer';
import { Alert , Toast} from 'react-bootstrap';
import $ from 'jquery';
import './Home.css'
import axios from 'axios'

const Home =()=>{
	const [initialFormState, setForm] = useState({email:''})
	const[bg, setPageBg] = useState('url(https://res.cloudinary.com/www-softroniiks-com/image/upload/v1642640356/AfroWatch/AfroBackgroud_swmppf.png')
	const[initialAudioState, setAudioState] = useState({time: 4, buttonText:'pause', play:false, click:function(){}, url:'https://www.youtube.com/embed/a6O7dqFCox4?autoplay=1&loop=1'}); //DAA3W8_UdJk
	const background = initialAudioState.play ? 'bg-home-image-vis' : 'bg-home-image'

	const[alertState, setAlert] = useState({isAlert:false, msg:''})

	const axios_ = axios.create({baseURL: process.env.REACT_APP_BASE_URL})

	useEffect(()=>{
		if($(window).width() <= 600){
			alert('Open https://afrowatch.co on a PC or Mac for best viewing pleasure!')
		}else{
			console.log('> 600')
		}

	}, []);

	const submitForm = async(e)=>{
		e.preventDefault();

		if(initialFormState.email === ""){
			alert('please input a valid email address!')
			return;
		}

		const user = { email: initialFormState.email}

		try{
			const resp = await axios_.post(`/waitlist`, user);
			const {data} = resp.data;

			console.log(data)

			if(data.email){
				setAlert({...alertState, isAlert: true, msg:resp.data.message});  
			}

			if(data.code === 11000 || !resp.data.success){
				setAlert({...alertState, isAlert:true ,msg:resp.data.message})
			}

		}catch(err){
			console.log(err)
		}

		setForm({...initialFormState, email:''})      
	}

	const pauseAudio=()=>{
		setAudioState({...initialAudioState, buttonText:'play', play:false})
	}

	const playAudio=()=>{
		setAudioState({...initialAudioState, buttonText:'pause', play:true})
	}

	const windowWidth = $(window).width();

	return(  
		<>
			<div className={background}>
				{windowWidth >= 600 ? <NavBar routeBg={setPageBg}/> : null}
				<div className="container">
					<div className="row justify-content-end">
						<div className="col-md-3">
							{alertState.isAlert ? 
								<Toast show={true} onClick={()=>{setAlert({...alertState, isAlert:false})}} className="toast-style">
									<Toast.Header className="toast-header-style">
										<strong className="me-auto">Notification</strong>
									</Toast.Header>
									<Toast.Body style={{color:'black'}}>{alertState.msg}</Toast.Body>
								</Toast>
								: null}
						</div>
					</div> 

					<div className="row mt-5 justify-content-center">
						<div className="col-md-12 text-center">
							{/* {initialAudioState.play ? <img className="pause" onClick={pauseAudio} src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620946939/AfroWatch/Vector_1_jimjl6.svg" alt="pause-btn"/>: 
								<img onClick={playAudio} className="play mb-4" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620946412/AfroWatch/Component_3_zf2imv.svg" alt="play-btn"/>}

							<img className="screen" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1642656660/AfroWatch/empty_screen_oj6uuz.png" alt=""/>

							<iframe className="beta-video" title="beta-vid" src="https://www.youtube.com/embed/WtaxhAwgWPU?autoplay=1&loop=1&playlist=WtaxhAwgWPU&rel=0&listType=playlist&showinfo=0&controls=0" allow="autoplay" frameborder="0" width="500" height="300" allowFullScreen></iframe> */}
						</div>
					</div>    

					<div className="row mt-4 justify-content-start">
						<div className="col-md-6 text-start">
							<img src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1618744943/AfroWatch/Afrowatch-Logo--Vertical-White-font_h6sxho.png" alt="af_logo_top_left" className='home-af-logo' />
							<img className='pc' src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1717128604/AfroWatch/iMac_Mockup_24_inch_yckccv.png" alt=""/>
							
							<form onSubmit={submitForm} action="" method="post">
								<h1 className='notify-heading mt-4 mt-md-0'>Africas largest animation collection!</h1>
								<img className='green-curve' src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1717106050/AfroWatch/Vector_633_mmnugu.png" alt="" />
								
								<p className='my-5 waitlists-text text-white'>Get notified on when we launch</p>
								<p className="text-white my-5" style={{fontSize: (windowWidth >= 600) ?'20px' : '18px'}}>A one stop video streaming platform to <span style={{color:'#06BA83'}}>african animation</span>, movies and series</p>

								<label htmlFor="email" className="text-white">Your Email</label>
								<input className="form-control" type="email" name="email" id="email" value={initialFormState.email} onChange={(e)=>setForm({...initialFormState, email:e.target.value})} placeholder="Email address"/>
								
								<input className="submitBtn mt-3 px-4 py-2 mb-4 mb-md-0" type="submit" value="Submit" data-micron="bounce"/>
							</form>
						</div>
					</div>

					{/* {initialAudioState.play ?<iframe title="burna" width="0" height="0" style={{}} src={initialAudioState.url} allow="autoplay" frameborder="0"></iframe> :null} */}
				</div>
			</div>
			<Footer/>
		</>
	)
}

export default Home;