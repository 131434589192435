import { useEffect, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorIcon from '@mui/icons-material/Error';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import ProfileIcon from "./ProfileIcon";

import axios from "axios";

const EditProfile = ({ settingStyle }) => {
	const currentUser = localStorage.getItem("currentUser");
	const currentProfile = localStorage.getItem("profile_name");
	const currentProfileId = localStorage.getItem("profile_id");

	const [profileState, setProfile] = useState({
		fullName: currentProfile,
		id: "",
		email: "",
	});
	const [user_, setUser] = useState();
	const [profileNames, setprofileNames] = useState([]);
	const [profileIconFormActive, setProfileIconForm] = useState(false);
	const [buttonClass, setButtonClass] = useState({ show: {} });
	const [snackOpen, setSnackOpen] = useState(false);
	const [snackErrorOpen, setSnackErrorOpen] = useState(false);
	const [alertMessage, setAlertMessage] = useState("");
	const [alertErrorMessage, setAlertErrorMessage] = useState("");
	const [profileLoading, setProfileLoading] = useState(false);

	const axios_ = axios.create({ baseURL: process.env.REACT_APP_BASE_URL });

	const getUser = async () => {
		const resp = await axios_.get(`/user/${currentUser}`, { headers:{'Authorization': `Bearer ${localStorage.getItem('token')}`}});
		const { data } = resp.data;
		if (resp.data.success) {
			setUser(data);
		} else {
			console.log(resp.data.message);
		}
	};

	const updateProfileName = async (e, index, profile_id) => {
		e.preventDefault();
		setProfileLoading(true);
		try {
			let fullName = profileNames[index];

			const body = {
				fullName: fullName.trim(),
			};

			const resp = await axios_.put(
				`/${currentUser}/user/${profile_id}`,
				body
			);

			if (resp.data.success) {
				setProfileLoading(false);
				const { data } = resp.data;
				setProfile({ ...profileState, firstName: data.profile_name });
				setSnackOpen(true);
				setAlertMessage("Profile data updated successfully");
			} else {
				setProfileLoading(false);
				setSnackErrorOpen(true);
				setAlertErrorMessage(resp.data.message);
			}
		} catch (e) {
			setProfileLoading(false);
			setSnackErrorOpen(true); 
			setAlertErrorMessage('Error occured');
		}
	};

	const showUpdateButton = (index, status = true) => {
		const showObj = buttonClass.show;
		showObj[index] = status;
		setButtonClass({ show: showObj });
	};

	const updateProfileNameArray = (index, inputValue) => {
		const profileNameArray = profileNames;
		profileNameArray[index] = inputValue;
		setprofileNames([...profileNameArray]);
		showUpdateButton(index);
	};

	useEffect(() => {
		getUser();
	}, []);

	const updateProfile = async (e, index, profile_id) => {
		if (profileNames[index]) await updateProfileName(e, index, profile_id);
		showUpdateButton(index, false);
	};

	const handleSnackBarClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setSnackOpen(false);
		setSnackErrorOpen(false);
	};

	const action = (
		<>
			<IconButton
				size="small"
				aria-label="close"
				color="inherit"
				onClick={handleSnackBarClose}
			>
				<CloseIcon fontSize="small" />
			</IconButton>
		</>
	);

	const profileForm = (profile,pos) => (
		<form
			className="d-flex align-items-center flex-wrap"
			onSubmit={(e) => {
				updateProfile(
					e,
					pos,
					profile._id
				);
			}}
			action=""
			method="post"
		>
			<input
				id={profile._id}
				className="form-control my-3 col-8 col-md-6"
				type="text"
				name="firstName"
				onChange={(e) => {
					updateProfileNameArray(
						pos,
						e.target.value
					);
				}}
				value={
					profileNames[pos] ||
						profileNames[pos] === ""
						? profileNames[pos]
						: profile.profile_name
				}
				placeholder="First name"
				required
			/>
			{/* <img className='ml-3 mt-2' style={{width:'50px', height:'50px', borderRadius:'10px'}} src={profile.profile_icon} alt=""/> */}
			<ProfileIcon
				profile_id={profile._id}
				profile_icon={profile.profile_icon}
				switch={profileIconFormActive}
				setSwitch={() => {
					profileIconFormActive
						? setProfileIconForm(
							false
						)
						: setProfileIconForm(
							true
						);
				}}
			/>
			<button
				type="submit"
				style={settingStyle}
				className={
					buttonClass.show[pos] &&
						!profileLoading
						? "btn btn-danger btn-sm col-md-2 ml-md-5 my-1"
						: "d-none"
				}
			>
				{!profileLoading ? (
					"Update"
				) : (
					<CircularProgress
						style={{
							width: "20px",
							height: "20px",
						}}
						color="inherit"
					/>
				)}
			</button>
		</form>		
	)
	
	const profileFormNonPrimaryProfile = ()=>{
		const pos = user_.profiles.findIndex((profile)=>profile._id === currentProfileId);
		const profile = user_.profiles[pos];
		return (
			<div className='my-1'>
				<p
					style={settingStyle}
					className="bg-none"
				>
					Profile
				</p>
				{profileForm(profile,pos)}
			</div>
		)
	}

	return (
		<div className="container">
			<div className="row mt-5 d-flex">
				<div className="p-3 col-md-9">
					{user_
						? user_.profiles[0]._id === currentProfileId
							? user_.profiles.map((profile, pos) => {
								return (
									<div key={pos} className="my-1">
										<p
											style={settingStyle}
											className="bg-none"
										>
											Profile {pos + 1}
										</p>
										{profileForm(profile,pos)}
									</div>
								);
							})
							: user_.profiles.map((profile) => {
								return (
									<input
										onChange={(e) =>
											setProfile({
												...profileState,
												firstName: e.target.value,
											})
										}
										style={{
											backgroundColor: "inherit",
											color: "white",
										}}
										className="form-control mb-3"
										type="text"
										name="firstName"
										defaultValue={
											currentProfile
												? profile.profile_name
												: ""
										}
										placeholder="First name"
										required
										disabled
									/>
								);
							})
						: null}
				</div>
			</div>
			{snackOpen ? (
				<Snackbar
					open={snackOpen}
					autoHideDuration={8000}
					onClose={handleSnackBarClose}
					message={alertMessage}
					action={action}
					anchorOrigin={{ vertical: "top", horizontal: "right" }}
				>
					<Alert
						style={{ marginBottom: "1rem" }}
						icon={<CheckCircleOutlineIcon fontSize="inherit" />}
						sx={{ width: "100%" }}
						severity="success"
						onClose={handleSnackBarClose}
					>
						{alertMessage}
					</Alert>
				</Snackbar>
			) : null}
			{snackErrorOpen ? (
				<Snackbar
					open={snackErrorOpen}
					autoHideDuration={8000}
					onClose={handleSnackBarClose}
					message={alertErrorMessage}
					action={action}
					anchorOrigin={{ vertical: "top", horizontal: "right" }}
				>
					<Alert
						style={{ marginBottom: "1rem" }}
						icon={<ErrorIcon fontSize="inherit" />}
						sx={{ width: "100%" }}
						severity="error"
						onClose={handleSnackBarClose}
					>
						{alertErrorMessage}
					</Alert>
				</Snackbar>
			) : null}
		</div>
	);
};

export default EditProfile;
